import { Buttons, Image, Container, User } from "./styled";


function App() {
  return (
    <Container>
      <User>
        <Image>
          <img
            src="https://pbs.twimg.com/media/FOSM3ryXEAE4ICs.jpg:large"
            alt=""
          />
        </Image>
        <h3 className="name">Aliya Aural</h3>

        <p>Hello Lovebugs!!</p>
        <small>cum have fun with me on my other sites</small>
      </User>

      <Buttons>
        <button>Twitter</button>
        <button>OnlyFans</button>
        <button>link</button>
        <button>Instagram</button>
        <button>Snapchat</button>
        <button>Twitch</button>
      </Buttons>
    </Container>
  );
}

export default App;
