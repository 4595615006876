import styled from "styled-components";


export const Container = styled.div`
   text-align: center;
   display: flex;
   justify-content: center;
   flex-direction: column;
   padding: 5%;

`;

export const User = styled.div`
width: 250px;
margin: auto;
    display: flex;
    flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  p {
    font-size: 30px;
    font-family: "Sacramento", cursive;
  }

  small {
    font-size: 20px;
    font-weight: 200;
  }
`;

export const Image = styled.div`
width: 200px;
height: 200px;
margin: auto;
border-radius: 50%;
overflow: hidden;
border: 5px solid pink;
img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    object-fit: cover;
}
`;

export const Buttons = styled.div`
  display: grid;
  grid-gap: 30px;

  button {
    width: 200px;
    margin: auto;
    outline: none;
    border-radius: 4px;
    border: none;
    color: white;
    letter-spacing: 2px;
    font-size: 20px;
    padding: 15px;
    background: #ee8c9c;

   
    
    text-transform: uppercase;
    font-size: 1rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $color;
      border-radius: 10rem;
      z-index: -2;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: #f9a6b4;
      transition: all 0.3s;
      /* border-radius: 10rem; */
      z-index: -1;
    }
    &:hover {
      color: #fff;
      &:before {
        width: 100%;
      }
    }
  }
`;